import React, { Component } from 'react'

export default class IconTick extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="1024"
        height="1024"
        viewBox="0 0 1024 1024"
      >
        <title></title>
        <g id="icomoon-ignore"></g>
        <path d="M150.4785,44.59578c4.429,2.86667 5.69989,8.78394 2.83322,13.21294l-11.08922,17.1355c0.70233,3.58333 1.11083,7.26939 1.11083,11.05578c0,31.65278 -25.68056,57.33333 -57.33333,57.33333c-31.65278,0 -57.33333,-25.68056 -57.33333,-57.33333c0,-31.65278 25.68056,-57.33333 57.33333,-57.33333c14.30944,0 27.37667,5.25556 37.43389,13.92722l-33.89833,52.38833l-19.46944,-22.28833c-2.60389,-2.98611 -7.11889,-3.29667 -10.105,-0.69278c-2.98611,2.60389 -3.29667,7.11889 -0.69278,10.105l25.70444,29.47889c1.38556,1.55278 3.34444,2.46056 5.39889,2.46056c0.19111,0 0.35833,-0.02389 0.52556,-0.02389c2.24556,-0.16722 4.27611,-1.36167 5.49444,-3.24889l40.87389,-63.34378c2.86667,-4.43378 8.77917,-5.70228 13.21294,-2.83322z"></path>
      </svg>
    )
  }
}
