export const cartItemToValidObject = (data, quantity) => {
  const res = {};
  // if quantity available then update product quantity
  // otherwise delete that particular line item...
  quantity ? (res["quantity"] = quantity) : (res["quantity"] = 0);

  res["product_id"] = data["product_id"];
  res["sku"] = data["sku"];
  res["variant_id"] = data["variant_id"];

  return res;
};

export const cartFormToData = (data) => {  
  const res = [];
  const placeholder = "/img/default-bc-product.png";
  if (data) {
    data.forEach((i) => {
      res.push({
        id: i["variant_id"],
        product_id: i["product_id"],
        product_url: i["product_url"] || i["url"],
        sku: i["sku"],
        name: i["name"],
        price: i["price"] || i["original_price"] || "0",
        sale_price: i["sale_price"] || "0",
        retail_price: i["retail_price"] || "0",
        image_url: i["image_url"] || placeholder,
        name_1: i?.["name_1"] || "",
        name_2: i?.["name_2"] || "",
        value_1: i?.["label_1"] || "",
        value_2: i?.["label_2"] || "",
        inventory_level: i["inventory_level"] || 0,
        quantity: i["quantity"],
        maxPurchaseQuantity:
          i["maxPurchaseQuantity"] || i["order_quantity_minimum"] || 0,
        minPurchaseQuantity:
          i["minPurchaseQuantity"] || i["order_quantity_maximum"] || 0,
        totalPrice: i["extended_price"] || i["extended_list_price"],
      });
    });
  }

  return res;
};

export const cartToCartFormData = (data) => {  
  const res = [];
  const placeholder = "/img/default-bc-product.png";
  if (data) {
    data.forEach((i) => {
      const optionPairs = i['options'].map((option, index) => {
        const name = Object.keys(option)[0];
        const label = option[name];
        return { [`label_${index + 1}`]: label, [`name_${index + 1}`]: name };
      });
      const optionPairsObj = Object.assign({}, ...optionPairs);
      res.push({
        extended_price: i['extended_list_price'],
        extended_sale_price: i['extended_sale_price'],
        image_url: i['image_url']  || placeholder,
        inventory_level: i['inventory'],
        inventory_warning_level: 0,       
        maxPurchaseQuantity: i["order_quantity_maximum"] || null,        
        minPurchaseQuantity: i["order_quantity_minimum"] || null,
        name: i["name"],       
        price: i['list_price'],
        product_id: i["product_id"], 
        product_url: i["url"],
        quantity: i["quantity"],
        retail_price: null,
        sale_price: null,
        sku: i["sku"],
        upc: '',
        variant_id: i["variant_id"],
        ...optionPairsObj 
      });
    });
  }

  return res;
};

export const dataToLineItems = (data) => {
  const lowerInventoryLineItems = [];
  const line_items = [];

  if (data.length) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]["quantity"] > data[i]["inventory"]) {
        lowerInventoryLineItems.push(data[i]);
      } else if (
        data[i]["quantity"] < data[i]["order_quantity_minimum"] &&
        data[i]["order_quantity_minimum"] !== 0
      ) {
        lowerInventoryLineItems.push(data[i]);
      } else if (
        data[i]["quantity"] > data[i]["order_quantity_maximum"] &&
        data[i]["order_quantity_maximum"] !== 0
      ) {
        lowerInventoryLineItems.push(data[i]);
      } else {
        line_items.push(data[i]);
      }
    }
  }

  return {
    lowerInventoryLineItems: lowerInventoryLineItems,
    line_items: line_items,
  };
};
