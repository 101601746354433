// import { createCheckoutService } from "@bigcommerce/checkout-sdk";
// import { embedCheckout } from "@bigcommerce/checkout-sdk";
// let service = {};

function useSetCheckoutIframe() {
  // useEffect(() => {
  //   service = createCheckoutService();
  // }, []);

  // const embedCheckoutCall = (url) => {
  //   embedCheckout({
  //     url: url,
  //     containerId: "___gatsby",
  //   });
  // };

  const setIframe = async (url) => {
    try {
      const gatsbyElement = document.getElementById('___gatsby')
      const existingIframeElement = gatsbyElement.querySelector('iframe')

      if (existingIframeElement) {
        gatsbyElement.removeChild(existingIframeElement)
      }

      const iframeElement = document.createElement('iframe')
      iframeElement.src = url
      iframeElement.id = 'checkout-iframe'
      iframeElement.style.display = 'none'

      iframeElement.addEventListener('load', () => { })

      gatsbyElement.appendChild(iframeElement)
    } catch (e) {
      console.error(e)
    }
  }

  return { setIframe }
}

export default useSetCheckoutIframe
