import useSWR from "swr";
import { useSelector } from "react-redux";
import Api from "../../services/Api";
import { cartSummaryURL } from "../../ApiEndpoints";
import { useEffect, useRef } from "react";

function useGetCartSummary() {
  const { user } = useSelector((state) => state.auth);
  const cartId = user?.["cart_id"];
  const previousCartIdRef = useRef(cartId);

  const { data, error, mutate } = useSWR(
    cartId ? cartSummaryURL(cartId) : null,
    getCartSummary
  );

  const isLoading = !error && !data;

  useEffect(() => {
    if (cartId && cartId !== previousCartIdRef.current) {
      mutate();
      previousCartIdRef.current = cartId;
    }
  }, [cartId, mutate]);

  return {
    lineItemsLength: data?.["line_item_count"] || 0,
    isLoading,
    mutate,
  };
}

async function getCartSummary(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetCartSummary;
