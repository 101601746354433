import React, { useEffect, useState } from "react";
import useCartOperations from "../hooks/cart/useCartOperations";
import Loader from "../components/form/Loader";
import { staticPage } from "../../config/staticPage";

const Checkout = () => {
  const { refreshCheckout, checkoutLoading } = useCartOperations();
  const [iframeLoading, setIframeLoading] = useState(false);

  useEffect(() => {
    const checkoutIframe = document.querySelector("#checkout-iframe");

    if (!checkoutIframe) {
      setIframeLoading(true);
      refreshCheckout(setIframeLoading);
    }
  }, []);

  return iframeLoading || checkoutLoading ? <Loader /> : <></>;
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "checkout") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

export default Checkout;
