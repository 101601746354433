import React from "react";
import IconTick from "../../assets/icon_js/IconTick";

function Toast({ type, message }) {
  let iconSet = {
    "success" : {
      "class": "bc-toast-success",
      "icon" : <IconTick/>
    },
    "warning" : {
      "class": "bc-toast-warning",
      "icon" : <IconTick/>
    },
    "info" : {
      "class": "bc-toast-info",
      "icon" : <IconTick/>
    },
    "error" : {
      "class": "bc-toast-error",
      "icon" : <IconTick/>
    }
  };

  return (
  <div className={`bc-toast-message ${iconSet[type].class}`}> 
    {/* {iconSet[type].icon} */}
    <span>{message}</span>
  </div>
  );
}

export default Toast;
