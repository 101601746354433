import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Toast from '../../components/common/Toast'

function useToastify() {
  const toastMessage = (type, message) =>
    toast(<Toast type={type} message={message}></Toast>)

  return {
    toastMessage,
  }
}

export default useToastify
